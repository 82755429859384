import { Link } from "gatsby"
import * as React from "react"
import { Card, Tag } from "antd"
import placeholder from "../images/placeholder-vertical.jpeg"

const { Meta } = Card
const cardResults = ({ name, maker, invNumber, profileImg, seal }) => {
  profileImg = profileImg ? profileImg : placeholder
  let sealColor = null
  switch (seal) {
    case "Platinum":
      sealColor = "purple"
      break
    case "Gold":
      sealColor = "gold"
      break
    case "Silver":
      sealColor = "blue"
      break
    case "Bronze":
      sealColor = "lime"
      break
    default:
      sealColor = null
  }

  return (
    <div>
      <Link to={"/instrument/" + invNumber}>
        <Card
          title={invNumber}
          size="small"
          className="rounded-md h-96"
          hoverable
          // title="Default size card"
          // extra={<a href="#">More</a>}
          bordered={true}
          cover={
            <img
              className="object-scale-down h-52 mt-1 w-96 scale-100 "
              alt="example"
              src={profileImg}
            />
          }
          // seal text
          // extra={seal ? <Tag color={sealColor}>{seal}</Tag> : null}
        >
          {/* {console.log(typeof maker)} */}
          {maker === "undefined undefined" ? (
            <Meta title={name} description={""} />
          ) : (
            <Meta title={name} description={maker} />
          )}
        </Card>
      </Link>
    </div>
  )
}

export default cardResults
