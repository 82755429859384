import React, { Fragment, useState } from "react"
import { orderBy } from "lodash"
import { navigate } from "gatsby"
import { MinusSmIcon, PlusSmIcon } from "@heroicons/react/solid"
import { XIcon } from "@heroicons/react/outline"
import { Dialog, Disclosure, Transition } from "@headlessui/react"
import { RefinementList, ToggleRefinement } from "react-instantsearch-dom"
import "instantsearch.css/themes/satellite.css"
import "./algRefinementList.css"

const typeRefLst = (
  <div className="w-full pl-1 pr-4">
    <div className="w-full max-w-sm  mx-auto border-b border-gray-200 ">
      <Disclosure defaultOpen={true}>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between w-full py-2 text-sm font-medium text-left text-gray-900 bg-white rounded-lg  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-50">
              <span>Instrument</span>
              <span className="ml-6 flex items-center">
                {open ? (
                  <MinusSmIcon className="h-5 w-5" aria-hidden="false" />
                ) : (
                  <PlusSmIcon className="h-5 w-5" aria-hidden="false" />
                )}
              </span>
            </Disclosure.Button>
            {/* Using `static`, `Disclosure.Panel` is always rendered and
                ignores the `open` state.
            */}
            <Disclosure.Panel>
              <RefinementList
                attribute="type"
                // transformItems={items => orderBy(items, "count", "asc")}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  </div>
)

const countryRefLst = (
  <div className="w-full pl-1 pr-4 ">
    <div className="w-full max-w-sm  mx-auto border-b border-gray-200 ">
      <Disclosure defaultOpen={true}>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between w-full py-2 text-sm font-medium text-left text-gray-900 bg-white rounded-lg  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-50">
              <span>Country of Origin</span>
              <span className="ml-6 flex items-center">
                {open ? (
                  <MinusSmIcon className="h-5 w-5" aria-hidden="true" />
                ) : (
                  <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
                )}
              </span>
            </Disclosure.Button>

            <Disclosure.Panel>
              <RefinementList
                attribute="country"
                showMore={true}
                // searchable={true}
                showMoreLimit={100}
                limit={25}
                // transformItems={items => orderBy(items, "count", "desc")}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  </div>
)

const makerRefLst = (
  <div className="w-full pl-1 pr-4">
    <div className="w-full max-w-sm  mx-auto  border-gray-200 ">
      <Disclosure defaultOpen={true}>
        {({ open }) => (
          <>
            <Disclosure.Button className="flex justify-between w-full py-2 text-sm font-medium text-left text-gray-900 bg-white rounded-lg  focus:outline-none focus-visible:ring  focus-visible:ring-opacity-50">
              <span>Maker</span>
              <span className="ml-6 flex items-center">
                {open ? (
                  <MinusSmIcon className="h-5 w-5" aria-hidden="true" />
                ) : (
                  <PlusSmIcon className="h-5 w-5" aria-hidden="true" />
                )}
              </span>
            </Disclosure.Button>

            <Disclosure.Panel>
              <RefinementList
                attribute="maker"
                showMore={true}
                searchable={true}
                showMoreLimit={100}
                limit={15}
                // transformItems={items => orderBy(items, "count", "asc")}
              />
            </Disclosure.Panel>
          </>
        )}
      </Disclosure>
    </div>
  </div>
)

const saleRefLst = (
  <div className="w-full pl-1 pr-4">
    <div className="w-full max-w-sm  mx-auto border-b border-gray-200 "></div>
    <ToggleRefinement attribute="onSale" label="Sale" value={true} />
  </div>
)
const loanRefLst = (
  <div className="w-full pl-1 pr-4">
    <div className="w-full max-w-sm  mx-auto border-b border-gray-200 "></div>
    <ToggleRefinement attribute="onLoan" label="Loan" value={true} />
  </div>
)

const backBtn = (
  <button
    className="hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
    onClick={() => navigate("/")}
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-6 w-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M11 17l-5-5m0 0l5-5m-5 5h12"
      />
    </svg>
    Back
  </button>
)

const Filters = () => {
  const [mobileFiltersOpen, setMobileFiltersOpen] = useState(false)

  return (
    <div className="lg:w-60 lg:pr-3">
      {/* Mobile Mode */}
      <Transition.Root show={mobileFiltersOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 lg:hidden"
          onClose={setMobileFiltersOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="translate-x-full"
          >
            <div className=" pl-4  relative max-w-full w-full h-full bg-white shadow-xl py-4 pb-12 flex flex-col overflow-y-auto">
              <div className="px-4 flex items-center justify-between ">
                <button
                  type="button"
                  className="-mr-2 w-10 h-10 bg-white p-2 rounded-md flex items-center justify-center text-gray-400"
                  onClick={() => setMobileFiltersOpen(false)}
                >
                  <span className="sr-only">Close menu</span>
                  <XIcon className="h-6 w-6" aria-hidden="true" />
                </button>
              </div>
              {/* Filters */}
              {typeRefLst}
              {countryRefLst}
              {makerRefLst}
              {/* {saleRefLst} */}
              {/* {loanRefLst} */}
            </div>
          </Transition.Child>
        </Dialog>
      </Transition.Root>

      <div className=" w-full items-baseline  pt-0  lg:hidden ">
        <div className="flex  w-auto justify-between items-center ">
          <div className="inline">{backBtn}</div>

          <button
            type="button"
            className=" hover:bg-gray-300 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
            onClick={() => setMobileFiltersOpen(true)}
          >
            Filter
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M3 3a1 1 0 011-1h12a1 1 0 011 1v3a1 1 0 01-.293.707L12 11.414V15a1 1 0 01-.293.707l-2 2A1 1 0 018 17v-5.586L3.293 6.707A1 1 0 013 6V3z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>
      </div>

      {/* standard Mode */}
      <div className="hidden lg:block ">
        <div className=" pb-2 flex flex-row justify-between">
          <div className="flex flex-row">{backBtn}</div>
        </div>
        {typeRefLst}
        {countryRefLst}
        {makerRefLst}
        {/* {saleRefLst} */}
        {/* {loanRefLst} */}
      </div>
    </div>
  )
}

export default Filters
