import * as React from "react"
import {
  connectHits,
  connectStateResults,
  ClearRefinements,
} from "react-instantsearch-dom"
import CardResults from "../cardResults"
import Filters from "./algRefinementList"

// const Results = connectStateResults(
//   ({ searchState, searchResults, children }) =>
//     searchResults && searchResults.nbHits !== 0 ? (
//       children
//     ) : (
//       <div>No results have been found for {searchState.query}.</div>
//     )
// );

const Hits = ({ hits }) => (
  // Grid style
  <div className="mt-11  grid grid-cols-1  md:grid-cols-3 gap-x-4 gap-y-5  ">
    {hits.map(hit => (
      <CardResults
        name={hit.name}
        maker={hit.maker}
        invNumber={hit.invNumber}
        profileImg={hit.profileImg}
        key={hit.invNumber}
        seal={hit.seal}
      />
    ))}
  </div>
)
const CustomHits = connectHits(Hits)

const Results = connectStateResults(
  ({ searchState, searchResults, children }) =>
    // searchState && searchState.query ? (
    searchResults && searchResults.nbHits !== 0 ? (
      children
    ) : (
      <div className="text-md py-20 px-36 w-full flex-col justify-center ">
        No results have been found&nbsp;
        <span className=" underline hover:no-underline">
          {searchState.query}.
        </span>
        <ClearRefinements className="text-red-600" />
      </div>
    )
  // ) : (

  //   )
)

const AlgHits = () => {
  return (
    // <Results>
    <div className="w-full lg:flex ">
      <div className=" lg:w-3/12  ">
        <Filters />
      </div>
      <Results>
        <CustomHits />
      </Results>
    </div>
    /* </Results> */
  )
}

export default AlgHits
