import * as React from "react"
import { connectSearchBox } from "react-instantsearch-dom"

const SearchBox = ({ currentRefinement, refine }) => {
  return (
    <div>
      <input
  type="text"
  className="w-full rounded p-4  "
  placeholder="Search: Name, Maker, Year, Type, Country, City"
  // two options: 1)real-time search as user types, or 2) hit button to search
  value={currentRefinement}
  onChange={event => refine(event.currentTarget.value)}
  // onSearch={value => refine(value)}
  />
    </div>
  )
}
const CustomSearchBox = connectSearchBox(SearchBox)

const AlgSearchBox = ({ entry }) => {
  return <CustomSearchBox defaultRefinement={entry} />
}

export default AlgSearchBox
