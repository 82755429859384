import * as React from "react"
import {
  connectHits,
  connectStateResults,
  ClearRefinements,
} from "react-instantsearch-dom"
import Filters from "./algRefinementList"
import ListResults from "../listResults"

const Hits = ({ hits }) => (
  // List style
  <div className="mt-11 grid grid-cols-1 grid-flow-row auto-rows-max">
    {hits.map(hit => (
      <ListResults
        name={hit.name}
        maker={hit.maker}
        invNumber={hit.invNumber}
        profileImg={hit.profileImg}
        key={hit.invNumber}
        seal={hit.seal}
      />
    ))}
  </div>
)
const CustomHits = connectHits(Hits)

const Results = connectStateResults(
  ({ searchState, searchResults, children }) =>
    // searchState && searchState.query ? (
    searchResults && searchResults.nbHits !== 0 ? (
      children
    ) : (
      <div className="text-md py-20 px-36 w-full flex-col justify-center ">
        No results have been found&nbsp;
        <span className=" underline hover:no-underline">
          {searchState.query}.
        </span>
        <ClearRefinements className="text-red-600" />
      </div>
    )
  // ) : (

  //   )
)

const AlgHitsList = () => {
  return (
    // <Results>
    <div className="w-full lg:flex ">
      <div className=" lg:w-3/12  ">
        <Filters  />
      </div>

      <Results>
        <CustomHits />
      </Results>
    </div>
    /* </Results> */
  )
}

export default AlgHitsList
