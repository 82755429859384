import React, { useState } from "react"
import algoliasearch from "algoliasearch/lite"
import { InstantSearch, Pagination, Configure } from "react-instantsearch-dom"
import AlgHits from "./algHits"
import AlgHitsList from "./algHitsList"
import AlgSearchBox from "./algSearchBox"
import "./algoliaSearch.css"

const searchClient = algoliasearch(
  "5C0BCXAZN7",
  "ce4b34cf15b89400068e9dec379457c2"
)

const AlgoliaSearch = () => {
  
  const [showListCard, setShowListCard] = useState(false)

  const gridBtn = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path d="M5 3a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2V5a2 2 0 00-2-2H5zM5 11a2 2 0 00-2 2v2a2 2 0 002 2h2a2 2 0 002-2v-2a2 2 0 00-2-2H5zM11 5a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V5zM11 13a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z" />
    </svg>
  )
  const listBtn = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="h-5 w-5"
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        d="M3 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zm0 4a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
        clipRule="evenodd"
      />
    </svg>
  )

  const handleListBtnClick = () => {
    setShowListCard(true)
    //console.log(showListCard)
  }

  const handleGridBtnClick = () => {
    setShowListCard(false)
  }

  return (
    <InstantSearch searchClient={searchClient} indexName="syfir-instrument">
      <Configure 
      filters="public:true"
      hitsPerPage={21} />

      
      <AlgSearchBox />

      <div className="pt-28 flex justify-center  relative">
        <div className="absolute pt-3  " style={{ right: "0%" }}>
          <button
            className="hidden lg:inline-block"
            // style={{ left: 410, top: 485 }}
            onClick={handleListBtnClick}
          >
            {listBtn}
          </button>
          <button
            className="pl-2 hidden lg:inline-block"
            // style={{ left: 440, top: 485 }}
            onClick={handleGridBtnClick}
          >
            {gridBtn}
          </button>
        </div>

        <div className="">
          {showListCard ? <AlgHitsList/> : <AlgHits />}
        </div>

        {/* <div className="border-dashed border-2 border-indigo-600"></div>
        <button>{listBtn}</button> */}
      </div>

      <div className="pt-20 flex justify-center  ">
        <Pagination
          // Optional parameters
          defaultRefinement={1}
          showFirst={true}
          showPrevious={true}
          showNext={true}
          showLast={true}
          padding={1}
          //totalPages={10}
          // translations={object}
        />
      </div>
    </InstantSearch>
  )
}

export default AlgoliaSearch
