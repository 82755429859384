import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"

import "./index.css"
import Seo from "../components/seo"
import AlgoliaSearch from "../components/search/algoliaSearch"
import { Link } from "gatsby"
import Footer from "../components/footer"

const SearchPage = props => {
  // const sale =
  //   props.location.state == null || props.location.state.sale == null
  //     ? false
  //     : props.location.state.sale
  // const entry =
  //   props.location.state == null || props.location.state.entry == null
  //     ? ""
  //     : props.location.state.entry
  // console.log(props)
  return (
    <div className="flex flex-col h-screen justify-between">
      <Seo title="FindMyInstrument™" />

      <main>
        <div className="bg-hero-pattern h-96 -mb-100"></div>
        <div
          style={{
            margin: `0 auto`,
            maxWidth: 1080,
            padding: `0 1.0875rem 1.45rem`,
          }}
        >
          <div className="pt-20 flex justify-center ">
            <Link to="/">
            <StaticImage
                  src="../images/imir_logo.png"
                  width={120}
                  quality={100}
                  alt="FMI Logo"
                />
              {/* <span> </span>
              <StaticImage
                src="../images/ficec-logo.png"
                width={110}
                quality={100}
                alt="FICEC Logo"
              /> */}
            </Link>

            {/* Dropdown menu hided for phase1 development */}
            {/* <div className="flex items-center  ">
              <NavMenu />
            </div> */}
          </div>

         
          <div className="text-white text-center font-extrabold pt-8 pb-8 text-2xl sm:text-4xl ">
          FindMyInstrument™
          {/* <div className="text-xl sm:text-2xl pt-5 font-semibold">
          <p>Protect ┃ Identify ┃ Share</p>
          <p>Identifying musical instruments has never been easier</p>
          </div> */}
 
         
          </div>

          

          {/* <div className="hidden text-white text-center font-extrabold pt-16 pb-6 sm:block sm:text-xl  md:hidden lg:hidden ">
            Si-Yo
            <br />
            Fine Instrument Registry™
          </div> */}

          {/* <AlgoliaSearch sale={sale} entry={entry} /> */}
          <AlgoliaSearch/>
         
        </div>
      </main>

      <Footer />
      {/* <footer className="bg-black">
        <div className="py-5 text-white flex flex-row justify-center ">
          v1.2.531 © {new Date().getFullYear()}&nbsp;
          <a className="text-white " href="https://www.si-yomusicsociety.org/">
            Si-Yo Music Society Foundation, Inc. search.js
          </a>
        </div>
      </footer> */}
    </div>
  )
}

export default SearchPage
