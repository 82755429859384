import { Link } from "gatsby"
import * as React from "react"
import { Tag } from "antd"

const ListResults = ({ name, maker, invNumber, profileImg, seal }) => {
  let sealColor = null
  switch (seal) {
    case "Platinum":
      sealColor = "purple"
      break
    case "Gold":
      sealColor = "gold"
      break
    case "Silver":
      sealColor = "blue"
      break
    case "Bronze":
      sealColor = "lime"
      break
    default:
      sealColor = null
  }

  return (
    <Link to={"/instrument/" + invNumber}>
      <div className=" w-full  mb-5 rounded-md border lg:max-w-full lg:flex  hover:shadow-md transform hover:scale-101 duration-500">
        <div
          className=" h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden"
          style={{
            backgroundImage: `url(${profileImg})`,
          }}
        ></div>
        {/* border-gray-400  */}
        <div
          style={{ width: "570px" }}
          className="w-full bg-white rounded-b lg:rounded-b-none lg:rounded-r p-4 block justify-between leading-normal"
        >
          <div className="mb-8 ">
            <p className="text-sm text-gray-600 flex items-center">
              {invNumber} &nbsp;
              {/* {seal ? <Tag color={sealColor}>{seal}</Tag> : null} */}
            </p>
            <div className="text-gray-900 font-bold text-xl mb-2">{name}</div>
            <p className="text-gray-700 text-base"></p>
          </div>
          <div className="flex items-center ">
            <div className="text-sm">
              <p className="text-gray-900 leading-none">{maker}</p>
            </div>
          </div>
        </div>
      </div>
    </Link>
  )
}

export default ListResults
